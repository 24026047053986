module.exports = {
  API_URL: "https://api.learnlivedev.ilserver.cloud",
  //API_UR: "https://api.learnliveuat.ilserver.cloud",
  // API_URL: "http://localhost:8003",
  WEB_URL: "https://learnlivedev.ilserver.cloud",
  LIMIT: 10,
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
} 